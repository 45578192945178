import { Audit } from '@app/shared/audit.model';
import { PortalProcessStrategyEquipment } from '@app/shared/business-continuity/portal-process-strategy-equipment.model';
import { PortalProcessStrategyAssignee } from '@app/shared/business-continuity/portal-process-strategy-assignee.model';
import { PortalProcessStrategyVendor } from '@app/shared/business-continuity/portal-process-strategy-vendor.model';
import { PortalProcessStrategyLocation } from '@app/shared/business-continuity/portal-process-strategy-location.model';
import { PortalProcessStrategyEmployee } from '@app/shared/business-continuity/portal-process-strategy-employee.model';
import { PortalProcessStrategyTechnology } from '@app/shared/business-continuity/portal-process-strategy-technology.model';
import { Document } from '@app/shared/document.model';
import { BCPlanActivationIssue } from '@app/shared/business-continuity/bc-plan-activation-issue';
import { AbsoluteDateModel } from '@app/shared/business-continuity/process-critical-date.model';
import { PortalProcessDependency } from '@app/shared/business-continuity/portal-process.model';

export class PortalProcessStrategy extends Audit {
	portalProcessStrategyId: string;
	portalProcessId: string;
	impactTimespanId: string;
	strategyTypeId?: string;
	strategyTypeNameAsBase64: string = null;
	strategyTypeName: string = null;
	instructionsAsBase64: string = null;
	portalProcessLocationId: string = null;
	locationId: string = null;
	portalProcessLocationNameAsBase64: string = null;
	portalProcessLocationName: string = null;
	instructions: string = null;
	portalProcessStrategyStatusTypeId: string = null;
	equipments?: Array<PortalProcessStrategyEquipment> = new Array<PortalProcessStrategyEquipment>();
	processDependencies?: Array<PortalProcessDependency> = new Array<PortalProcessDependency>();
	assignees?: Array<PortalProcessStrategyAssignee> = new Array<PortalProcessStrategyAssignee>();
	vendors?: Array<PortalProcessStrategyVendor> = new Array<PortalProcessStrategyVendor>();
	locations?: Array<PortalProcessStrategyLocation> = new Array<PortalProcessStrategyLocation>();
	employees?: Array<PortalProcessStrategyEmployee> = new Array<PortalProcessStrategyEmployee>();
	technologies?: Array<PortalProcessStrategyTechnology> = new Array<PortalProcessStrategyTechnology>();
	documents?: Array<Document> = new Array<Document>();

	constructor(portalProcessStrategyId: string, portalProcessId: string, impactTimespanId: string, strategyTypeId?: string) {
		super();
		this.portalProcessStrategyId = portalProcessStrategyId;
		this.portalProcessId = portalProcessId;
		this.impactTimespanId = impactTimespanId;
		this.strategyTypeId = strategyTypeId; // was originally default to work from home. w/ addition of multiple strat/multiple locations, need to default to none.
	}
}

export class PortalProcessPlanStrategy extends PortalProcessStrategy {
	planActivationProcessId: string = null;
	planActivationId: string = null;
	portalProcessStrategyId: string = null;
	activationProcessStateTypeId: string = null;
	strategyTypeName: string = null;
	isAuthorized: boolean = false;
	isMobilized: boolean = false;
	isContacted: boolean = false;
	isVerified: boolean = false;
	criticalDate?: Date = null;
	criticalDateAbsolute: AbsoluteDateModel = null;
	notes: Array<PortalProcessPlanNote> = [];
	issues: Array<BCPlanActivationIssue> = null;

	constructor(portalProcessStrategyId: string, portalProcessId: string, impactTimespanId: string, strategyTypeId?: string) {
		super(portalProcessStrategyId, portalProcessId, impactTimespanId, strategyTypeId);
	}
}

export class PortalProcessPlanNote extends Audit {
	planActivationProcessNoteId: string = null;
	planActivationProcessId: string = null;
	noteAsBase64: string = null;
	note: string = null;
	createdByNameAsBase64: string = null;
	createdByName: string = null;

	constructor() {
		super();
	}
}
