import { User } from '@app/shared/user.model';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { Process } from '@app/shared/business-continuity/process.model';
import { ImpactTimespan } from '@app/shared/business-continuity/impact-timespan.model';
import { PortalProcessImpact } from '@app/shared/business-continuity/portal-process-impact.model';
import { AbsoluteDateModel, ProcessCriticalDate } from '@app/shared/business-continuity/process-critical-date.model';
import { RevisionStatusStyle } from '@app/shared/business-continuity/revistion-status-style.model';
import { PortalFunctionalAreaMessageRecipient } from '@app/shared/business-continuity/portal-function-area-message-recipient.model';
import { PortalProcessStrategy } from '@app/shared/business-continuity/portal-process-strategy.model';
import { RevisionItem } from '@app/shared/business-continuity/revision-item.model';
import { BCPlanActivationIssue } from '@app/shared/business-continuity/bc-plan-activation-issue';

export class PortalProcess {
	portalProcessId: string = null;
	portalId: string = null;
	portalProcessNameAsBase64: string = '';
	portalProcessName: string = null;
	portalProcessDescriptionAsBase64: string = null;
	portalProcessDescription: string = null;
	rtoImpactTimespan: ImpactTimespan = null;
	mtdImpactTimespan: ImpactTimespan = null;
	portalFunctionalArea: PortalFunctionalArea = null;
	portalProcessStatusTypeId: string = null;
	process: Process = null;
	responsibleUser: User = null;
	alternateResponsibleUsers: Array<User>;
	processImpacts: Array<PortalProcessImpact>;
	criticalDates: Array<ProcessCriticalDate>;
	impactTimespanStrategies: Array<PortalProcessStrategy>;
	revisionItems: Array<RevisionItem>;
	dateTimeCreated: Date = null;
	createdById: string = null;
	dateTimeLastModified: Date = null;
	lastModifiedById: string = null;
	isCustom: boolean = false;
	isHidden: boolean = false;
	documentFolderId: string = null;
	processDependencies: Array<PortalProcessDependency>;
	// status of process, assigned using RevPeriod
	status: string;

	// for UI use only
	alias: string = ''; // used for renaming - store the changes/new name
	isRenaming: boolean = false;
	style: RevisionStatusStyle;
	dueDate: Date = null;
	originalDueDate: Date = null;
	messageRecipient: PortalFunctionalAreaMessageRecipient = null;

	// process progress
	portalProcessProgressId: string;
	progressPct: number;
	firstNotCompleteRevisionItemTypeId: string;
	firstNotCompleteRevisionItemStateId: string;

	constructor() {
	}
}

export class PortalProcessPlan extends PortalProcess {
	planActivationProcessId: string = null;
	planActivationId: string = null;
	portalProcessStrategyId: string = null;
	activationProcessStateTypeId: string = null;
	activationProcessStateTypeName: string = null;
	strategyTypeName: string = null;
	isAuthorized: boolean = false;
	isMobilized: boolean = false;
	isContacted: boolean = false;
	isVerified: boolean = false;
	criticalDate?: Date = null;
	criticalDateAbsolute: AbsoluteDateModel = null;
	issues: Array<BCPlanActivationIssue> = [];

	// UI usage only
	impactTimespanOrder: number = null;

	constructor() {
		super();
	}
}

export class PortalProcessDependency {
	portalProcessDependencyId: string;
	portalProcessId: string;
	dependentPortalProcessId: string;
	dependentPortalProcessNameAsBase64: string;
	dependentPortalProcessName: string;
	dependentPortalFunctionalAreaNameAsBase64: string;
	dependentPortalFunctionalAreaName: string;
	dependentPortalFunctionalAreaId: string;
	portalProcessDependencyStatusTypeId: string;
}

export class PortalProcessLite {
	portalProcessId: string;
	portalProcessNameAsBase64: string;
	portalFunctionalAreaId: string;
	portalFunctionalAreaNameAsBase64: string;
	isCustom: string;
}
